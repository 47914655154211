import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import appMockup from "../images/appMockUp.png";
import { Button } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    marginTop: 200,
  },
  mockup: {
    width: "75%",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto"
  },
  downloadbtn: {
    color: "white",
    backgroundColor: "red",
    fontFamily: "Poppins",
    borderRadius: "10px",
    fontSize: "10px",
    "&:hover": {
      backgroundColor: "white",
      color: "red"
    },
    marginLeft: "auto",
    marginRight: "auto",
    display: "block",
  }
});

const DownloadApp = () => {
  const classes = useStyles();
  return (
    <Fragment>
      <Grid container spacing={0} className={classes.root}>
        <Grid item xs={2} />
        <Grid item justify="center" xs={8}>
          <img className={classes.mockup} src={appMockup} />
        </Grid>
        <Grid item xs={2} />

        <Grid item xs={2} />
        <Grid item justify="center" xs={8}>
          {/* <Button className={classes.downloadbtn}>Download App</Button> */}
        </Grid>
        <Grid item xs={2} />
      </Grid>
    </Fragment>
  );
};

export default DownloadApp;
