import React, { Fragment } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

import Rating from "../images/rateImg.png";
import Find from "../images/findImg.png";
import Share from "../images/shareImg.png";


const useStyles = makeStyles({
  feature: {
    minWidth: 100,
    textAlign: "left",
    marginTop: 100
    // paddingRight: 100
  },
  title: {
    fontSize: 16,
    fontFamily: "Poppins",
    color: "red"
  },
  header: {
    fontSize: 28,
    fontFamily: "Poppins",
    paddingRight: 100,
    marginBottom: 10
  },
  body: {
    fontSize: 16,
    fontFamily: "Poppins",
    paddingRight: 50
  },
  rating1: {
    marginTop: 100
  }
});

const Features = props => {
  const classes = useStyles();

  return (
    <Fragment>
      <Grid item xs={2} />
      <Grid item xs={5} style={{marginTop: 300}}>
        <div className={classes.feature} >
          <Typography className={classes.title}>Rate</Typography>
          <Typography className={classes.header}>
            Rate Restaurants
          </Typography>
          <Typography className={classes.body}>
            We want to turn restaurant reviews into a genuine source of information again. Users should never
            have to second guess if a rating is reliable, nor will restaurant owners see hateful comments on their
            hard work and passions. Tastebuds is a peaceful environment to share your opinions.
          </Typography>
        </div>
      </Grid>
      <Grid item xs={3}>
        <img className={classes.rating1} src={Rating} />
      </Grid>
      <Grid item={2} />

      <Grid item xs={2} />
      <Grid item xs={5}>
        <div className={classes.feature}>
          <Typography className={classes.title}>Discover</Typography>
          <Typography className={classes.header}>
          Find Suggestions
          </Typography>
          <Typography className={classes.body}>
            We specialize in building genuine relationships within the food
            industry through connecting communities together. We want to be able
            to connect restaurant owners, foodies, influencers, and everyone in
            between together through our platform, allowing them to discover new gems.
          </Typography>
        </div>
      </Grid>
      <Grid item xs={3}>
        <img className={classes.rating1} src={Find} />
      </Grid>
      <Grid item={2} />
      <Grid item xs={2} />
      <Grid item xs={5}>
        <div className={classes.feature}>
          <Typography className={classes.title}>Share</Typography>
          <Typography className={classes.header}>
            Share Your Gems
          </Typography>
          <Typography className={classes.body}>
            Sharing is caring. We want to broadcast your voice, whether you are a super taster or a new restaurant owner. The world
            needs to hear about your new discoveries and Tastebuds will be the platform to share that with a community of like minded tastebuds.
          </Typography>
        </div>
      </Grid>
      <Grid item xs={3}>
        <img className={classes.rating1} src={Share} />
      </Grid>
      <Grid item={2} />
    </Fragment>
  );
};

export default Features;
