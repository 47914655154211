import * as firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCksDsSy5v19Nap5sJQj02SAdzLYVnO2HY",
  authDomain: "dishionary-hao.firebaseapp.com",
  databaseURL: "https://dishionary-hao.firebaseio.com",
  projectId: "dishionary-hao",
  storageBucket: "dishionary-hao.appspot.com",
  messagingSenderId: "735408689500",
  appId: "1:735408689500:web:0ef5ca95db464f0c",
  measurementId: "G-PT83TFLEVG"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const projectFirestore = firebase.firestore();

export { projectFirestore };
