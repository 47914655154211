import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Rating from "../images/rateImg.png";

const useStyles = makeStyles({
  root: { marginTop: 50 },
  media: { height: 150 },
  title: {
    fontSize: 36,
    fontFamily: "Poppins",
    textAlign: "center",
    marginTop: 100
  },
  body: {
    fontSize: 16,
    fontFamily: "Poppins",
    marginTop: 20
  },
  restaurants: {
    marginLeft: 50,
    marginRight: 50
  },
  cardContent: {
    fontFamily: "Poppins"
  }
});

const FeaturedRestaurants = () => {
  const classes = useStyles();

  return (
    <Fragment>
      <Grid item xs={2} />
      <Grid item justify="center" xs={8}>
        <Typography className={classes.title}>Our Partners</Typography>
        <Typography className={classes.body}>
          We specialize in building genuine relationships within the food
          industry through connecting communities together. We want to be able
          to connect restaurant owners, foodies, influencers, and everyone in
          between together through our platform.
        </Typography>
      </Grid>
      <Grid item xs={2} />

      <Grid container className={classes.restaurants} spacing={4}>
        <Grid item xs={3}>
          <Card className={classes.root}>
            <CardContent>
              <CardMedia
                className={classes.media}
                image={Rating}
                title="Contemplative Reptile"
              />
              <Typography
                gutterBottom
                className={classes.cardContent}
                variant="h5"
                component="h2"
              >
                Restaurant 1
              </Typography>
              <Typography
                className={classes.cardContent}
                variant="body2"
                color="textSecondary"
                component="p"
              >
                Insert something about the restaurant here
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card className={classes.root}>
            <CardContent>
              <CardMedia
                className={classes.media}
                image={Rating}
                title="Contemplative Reptile"
              />
              <Typography
                className={classes.cardContent}
                gutterBottom
                variant="h5"
                component="h2"
              >
                Restaurant 2
              </Typography>
              <Typography
                className={classes.cardContent}
                variant="body2"
                color="textSecondary"
                component="p"
              >
                Insert something about the restaurant here
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card className={classes.root}>
            <CardContent>
              <CardMedia
                className={classes.media}
                image={Rating}
                title="Contemplative Reptile"
              />
              <Typography
                className={classes.cardContent}
                gutterBottom
                variant="h5"
                component="h2"
              >
                Restaurant 3
              </Typography>
              <Typography
                className={classes.cardContent}
                variant="body2"
                color="textSecondary"
                component="p"
              >
                Insert something about the restaurant here
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card className={classes.root}>
            <CardContent>
              <CardMedia
                className={classes.media}
                image={Rating}
                title="Contemplative Reptile"
              />
              <Typography
                className={classes.cardContent}
                gutterBottom
                variant="h5"
                component="h2"
              >
                Restaurant 4
              </Typography>
              <Typography
                className={classes.cardContent}
                variant="body2"
                color="textSecondary"
                component="p"
              >
                Insert something about the restaurant here
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default FeaturedRestaurants;
