import React from "react";
import {isMobile} from 'react-device-detect';
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import backgroundImage from "../images/HeaderImage.png";
import appMockup from "../images/appMockUpBackground.png";
import featureContent from "../images/FeatureContent.png";


import MissionStatement from "./MissionStatement";
import Features from "./Features";
import FeaturedRestaurants from "./FeaturedRestaurants";
import DownloadApp from "./DownloadApp";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    marginTop: "0%",
    padding: 20
  }
}));

const LandingPage = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        {!isMobile && <img src={backgroundImage} style={{position: 'absolute', top: 0, right: 0, width: "100%", height: "100%", objectFit: "cover"}}/>}
        {isMobile && <img src={backgroundImage} style={{position: 'absolute', top: 70, right: 0, width: "100%", height: "30%", objectFit: "cover"}}/>}
        <MissionStatement />
        <img src={appMockup} style={{marginTop: "25%", width: "100%", marginLeft: -30, objectFit: "contain"}}/>
        {/* <DownloadApp /> */}
        {/* <Features /> */}
        <img src={featureContent} style={{marginTop: "20%", width: "100%", marginBottom: 50, objectFit: "contain"}}/>
        {/* <FeaturedRestaurants /> */}
      </Grid>
    </div>
  );
};

export default LandingPage;
