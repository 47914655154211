import React, { Fragment } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import LandingPage from "./components/landing/LandingPage";
import Collections from "./components/collections/Collections";
import Collection from "./components/collections/Collection";
import Navbar from "./components/Navbar";
import "./fonts/Poppins-Regular.ttf";
import "./App.css";
import Footer from "./components/Footer";

function App() {
  return (
    <Router>
      <Navbar />
      <Route exact path="/" component={LandingPage} />
      <Route exact path="/collections" component={Collections} />
      <Route exact path="/collections/:id" component={Collection} />
      <Footer />
    </Router>
  );
}

export default App;
