import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import {isMobile} from 'react-device-detect';

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { Button } from "@material-ui/core";
import Face from "../images/missionstatement-face.png";
import Apple from "../images/GetApple.jpg";
import Google from "../images/GetGoogle.png";

const useStyles = makeStyles({
  root: {
    position: "relative",
    top: 200,
    left: "-17%",
  },
  card: {
    minWidth: 275,
    minHeight: 329,
    borderRadius: "54px",
    // textAlign: "center",
    marginTop: "150px",
    overflow: "visible",
    paddingBottom: "50px"
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)"
  },
  title: {
    fontSize: 36,
    fontFamily: "Poppins",
    fontWeight: "bold",
    marginTop: 20
  },
  body: {
    fontSize: 16,
    // marginLeft: 150,
    // marginRight: 150,
    // marginLeft: "15%",
    marginRight: isMobile ? "15%" : "50%",
    fontFamily: "Poppins",
    marginTop: 30
  },
  pos: {
    marginBottom: 12
  },
  downloadbtn: {
    color: "red",
    backgroundColor: "white",
    fontFamily: "Poppins",
    borderRadius: "10px",
    fontSize: "14px",
    "&:hover": {
      backgroundColor: "transparent",
      color: "transparent"
    },
    marginTop: 30,
    marginBottom: 10,
    // boxShadow: "1px 1px 10px #9E9E9E"
  },
  face: {
    marginTop: -80,
  }
});

const MissionStatement = () => {
  const classes = useStyles();

  return (
    <Fragment>
      <Grid item xs={2}></Grid>
      <Grid item justify="center" xs={8}>
        <div className={classes.root}>
          <div className={classes.card}>
            {/* <img className={classes.face} src={Face} /> */}
              <Typography className={classes.title}>
                Eat. Share. Repeat
              </Typography>
              <Typography className={classes.body}>
                We specialize in building genuine relationships within the food
                industry through connecting communities together. We want to be
                able to connect restaurant owners, foodies, influencers, and
                everyone in between together through our platform.
              </Typography>
              <Button className={classes.downloadbtn} onClick={() => {
                window.open("https://apps.apple.com/ca/app/tastebuds/id1454472881")
              }}><img src={Apple} style={{height: "55px", marginTop: "20px", width: isMobile ? 150 : null, objectFit: 'contain'}}></img></Button>
              <Button className={classes.downloadbtn} onClick={() => {
                window.open("https://play.google.com/store/apps/details?id=com.hao.tastebuds")
              }}><img src={Google} style={{height: "68px", marginTop: "20px", width: isMobile ? 160 : null, objectFit: 'contain', marginBottom: isMobile ? "80%" : 0}}></img></Button>
              {/* <Button className={classes.downloadbtn}>Download App</Button> */}
          </div>
        </div>
      </Grid>
      <Grid item xs={2}></Grid>
    </Fragment>
  );
};

export default MissionStatement;
