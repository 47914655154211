import { useState, useEffect } from "react";
import { projectFirestore } from "../config/config";

const useFirestoreCollections = data => {
  const [collections, setCollections] = useState([]);

  useEffect(() => {
    const unsub = projectFirestore.collection(data).onSnapshot(snap => {
      let documents = [];
      snap.forEach(doc => {
        documents.push({ ...doc.data(), id: doc.id });
      });
      setCollections(documents);
    });

    return () => unsub();
  }, [data]);

  return { collections };
};

export default useFirestoreCollections;
