import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {isMobile} from 'react-device-detect';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Button, Link } from "@material-ui/core";
import Tastebuds from "./images/tastebuds-logo.png";
import footerImg from "./images/Footer.png";


const useStyles = makeStyles({
  topBanner: {
    fontSize: 36,
    fontFamily: "Poppins",
    // backgroundColor: "#FF2534",
    height: "100px",
  },
  topText: {
    fontSize: 22,
    fontFamily: "Poppins",
    color: "white",
    marginLeft: 50
  },
  downloadbtn: {
    color: "white",
    backgroundColor: "#ff0116",
    fontFamily: "Poppins",
    borderRadius: "40px",
    fontSize: "14px",
    "&:hover": {
      backgroundColor: "#ff7c7c",
      color: "white",
    },
    width: isMobile ? "50%" : "30%",
    height: "7vh",
    float: "right",
    marginRight: isMobile ? 0 : 50
  },
  bottomBanner: {
    backgroundColor: "white",
    height: "200px",
    marginRight: 50
  },
  bottomLogo: {
    marginLeft: 50
  },
  legalText: {
    fontSize: 11,
    fontFamily: "Poppins",
    color: "black",
    marginTop: 12,
    marginLeft: 50
  },
  bottomText: {
    fontSize: 11,
    fontFamily: "Poppins",
    color: "black",
    marginRight: 50,
    marginTop: 12,
    float: "right"
  },
  bottomTextContent: {
    fontSize: 11,
    fontFamily: "Poppins",
    color: "black",
    marginRight: 5,
    marginTop: 12,
    float: "right"
  }
});

const Footer = () => {
  const classes = useStyles();
  return (
    <div>
      <img src={footerImg} style={{width: "100%", marginBottom: isMobile ? "-90%" : "-20%"}}/>
      <div className={classes.topBanner}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={0}
          className={classes.topBanner}
        >
          {/* <Grid item xs={6}>
            <Typography className={classes.topText}>
              Are you a restaurant?
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Button className={classes.downloadbtn} onClick={() => {
              window.open("mailto:tastebuds@hungrii.com")
            }}>Work with us</Button>
          </Grid> */}
          <Button className={classes.downloadbtn} onClick={() => {
              window.open("mailto:tastebuds@hungrii.com")
            }}>Work with us</Button>
        </Grid>
      </div>
      <div className={classes.bottomBanner}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={0}
          className={classes.bottomBanner}
        >
          <Grid item xs={6}>
            <img className={classes.bottomLogo} src={Tastebuds} />
            <Typography className={classes.legalText}>
              2021 - Tastebuds©. All rights reserved
            </Typography>
            {isMobile && <Typography className={classes.legalText}>
              Built with ❤ by a global team
            </Typography>}

            {isMobile &&<Typography className={classes.legalText} style={{marginLeft: 0}}>
            <Link className={classes.legalText} style={{marginRight: "0px"}} onClick={() => {
              window.open("mailto:tastebuds@hungrii.com")
            }}>
            Support
            </Link>
            </Typography>}
            {isMobile && <Typography className={classes.legalText} style={{marginLeft: 0}}>
            <Link className={classes.legalText} onClick={() => {
              window.open("https://hungrii.com/privacy")
            }}>
            Privacy
            </Link>
            </Typography>}
          </Grid>
          <Grid item xs={1} />
          {!isMobile && <Grid item xs={2}>
            <Typography className={classes.bottomText}>
              Built with ❤ by a global team
            </Typography>

            <Typography className={classes.bottomText}>
            <Link className={classes.bottomTextContent} style={{marginRight: "0px"}} onClick={() => {
              window.open("mailto:tastebuds@hungrii.com")
            }}>
            Support
            </Link>
            <Typography className={classes.bottomTextContent}>
               | 
            </Typography>
            <Link className={classes.bottomTextContent} onClick={() => {
              window.open("https://hungrii.com/privacy")
            }}>
            Privacy Policy
            </Link>
            </Typography>
          </Grid>}
        </Grid>
      </div>
    </div>
  );
};

export default Footer;
