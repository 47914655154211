import React from "react";
import useFirestoreCollections from "../../hooks/useFirestoreCollections";
const Collections1 = () => {
  const { collections } = useFirestoreCollections("Collections");
  console.log(collections);

  return (
    <div>
      {collections &&
        collections.map(collection => (
          <div key={collection.ID}>
            {collection.Name}: {collection.ID}
          </div>
        ))}
    </div>
  );
};

export default Collections1;
