import React from "react";
import { Link } from "react-router-dom";
import {isMobile} from 'react-device-detect';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TastebudsLogo from "./images/tastebuds-logo.png";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    paddingTop: "15px",
    backgroundColor: isMobile ? "#ffffff" : null,
    width: isMobile ? "100%" : "95%",
    zIndex: 10,
    position: "fixed",
    // boxShadow: "1px 1px 10px #9E9E9E"
  },
  navTextStyle: {
    marginTop: "3px",
  },
  downloadbtn: {
    color: isMobile ? "white" : "#ff0116",
    backgroundColor: isMobile ? "#ff0116" : "white",
    fontFamily: "Poppins",
    borderRadius: "30px",
    fontSize: "14px",
    width: 100,
    "&:hover": {
      backgroundColor: isMobile ? "white" : "red",
      color: isMobile ? "red" : "white"
    },
    marginRight: 30,
    marginBottom: isMobile ? 10 : null
  }
});

const Navbar = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <a href="/">
            <img className="tastebudsLogo" src={TastebudsLogo} style={{marginLeft: "20%"}} />
          </a>
        </Grid>
        {/* <Grid item xs={1} className={classes.navTextStyle}>
          <Link to="/features" className="link">
            <div className="navText">Features</div>
          </Link>
        </Grid> */}
        {/* <Grid item xs={1} className={classes.navTextStyle}>
          <Link to="/restaurants" className="link">
            <div className="navText">Restaurants</div>
          </Link>
        </Grid> */}
        {/* <Grid item xs={1} className={classes.navTextStyle}>
          <Link to="/team" className="link">
            <div className="navText">Team</div>
          </Link>
        </Grid>
        <Grid item xs={1} className={classes.navTextStyle}>
          <Link to="/join" className="link">
            <div className="navText">Join us</div>
          </Link>
        </Grid> */}
        {!isMobile && <Grid item xs={7}></Grid>}
        {isMobile && <Grid item xs={4}></Grid>}
        <Grid item xs={1}>
          <Link to="/" className="link">
            <Button className={classes.downloadbtn} onClick={() => {
              window.open("mailto:tastebuds@hungrii.com")
            }}>Join Us</Button>
          </Link>
        </Grid>
      </Grid>
    </div>
  );
};

export default Navbar;
