import React, { Fragment } from "react";
import { useParams } from "react-router-dom";
import useFirestoreCollections from "../../hooks/useFirestoreCollections";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import Map from "../images/Map.png";
import collectionProfilePic from "../images/collectionProfilePic.png";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import sampleFood from "../images/sampleFood.png";
import CardMedia from "@material-ui/core/CardMedia";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  cardRoot: {
    display: "flex"
  },
  details: {
    display: "flex",
    flexDirection: "column"
  },
  content: {
    flex: "1 0 auto"
  },
  header: {
    textAlign: "left",
    fontSize: 28,
    fontFamily: "Poppins",
    marginTop: 50,
    marginBottom: 20,
    paddingLeft: 52,
    fontWeight: 700
  },
  body: {
    fontSize: 16,
    fontFamily: "Poppins",
    color: "red",
    textAlign: "center"
  },
  blurb: {
    fontSize: 16,
    fontFamily: "Poppins",
    textAlign: "left",
    marginLeft: 20
  },
  dish: {
    width: 151
  },

  username: {
    fontSize: 16,
    fontFamily: "Poppins",
    textAlign: "right",
    color: "red"
  },
  collections: {
    marginTop: 50
  },
  collectionList: {
    fontSize: 16,
    fontFamily: "Poppins",
    textAlign: "left",
    marginLeft: 20
  }
}));

const Collection = props => {
  const classes = useStyles();
  const { collections } = useFirestoreCollections("Collections");
  const { id } = useParams();

  let collection = _.filter(collections, { ID: id });
  collection = collection[0];
  console.log(collection);

  console.log(id);
  return (
    <Box component={Grid} container boxShadow={6}>
      <Grid container className={classes.root}>
        <Grid justify="center" item xs={6}>
          <Grid container xs={12}>
            <Grid item xs={2} />
            <Grid item xs={10}>
              {collection && (
                <Typography className={classes.header}>
                  {collection.Name}
                </Typography>
              )}
            </Grid>
          </Grid>
          <Grid container xs={12}>
            <Grid container xs={4}>
              {collection && (
                <Fragment>
                  <Grid item xs={12}>
                    <img align="right" src={collectionProfilePic} />
                  </Grid>
                  <Grid xs={12}>
                    <Typography className={classes.username}>
                      {collection.CreatorUsername}
                    </Typography>
                  </Grid>
                </Fragment>
              )}
            </Grid>
            <Grid container xs={8}>
              {collection && (
                <Fragment>
                  <Grid xs={12}>
                    <Typography className={classes.blurb}>
                      Creator's note
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className={classes.blurb}>last seen</Typography>
                  </Grid>
                  <Grid alignItems="flex-end" xs={12}>
                    <Typography className={classes.blurb}>
                      Hello there!! These are my go-to brunch places with my
                      girls every sunday! I love starting off my lazy sunday
                      mornings with pancakes, waffles, or eggs!
                    </Typography>
                  </Grid>
                </Fragment>
              )}
            </Grid>

            {/* add .map() function when collection data set is in an array */}

            <Grid container className={classes.collections} xs={12}>
              <Grid item xs={2}></Grid>
              {collection && (
                <Grid item xs={10}>
                  <Card className={classes.cardRoot}>
                    <CardMedia
                      className={classes.dish}
                      component="img"
                      image={sampleFood}
                      title="sample food"
                    />
                    <div className={classes.details}>
                      <CardContent className={classes.content}>
                        <Typography>Belgian Waffle</Typography>
                        <Typography>May 28, 2019</Typography>
                        <Typography>857 Marine Drive West,</Typography>
                        <Typography>Vancouver, V7V 1H7, BC</Typography>
                      </CardContent>
                    </div>
                  </Card>
                </Grid>
              )}
            </Grid>

            <Grid container className={classes.collections} xs={12}>
              <Grid item xs={2}></Grid>
              {collection && (
                <Grid item xs={10}>
                  <Card className={classes.cardRoot}>
                    <CardMedia
                      className={classes.dish}
                      component="img"
                      image={sampleFood}
                      title="sample food"
                    />
                    <div className={classes.details}>
                      <CardContent className={classes.content}>
                        <Typography>Belgian Waffle</Typography>
                        <Typography>May 28, 2019</Typography>
                        <Typography>857 Marine Drive West,</Typography>
                        <Typography>Vancouver, V7V 1H7, BC</Typography>
                      </CardContent>
                    </div>
                  </Card>
                </Grid>
              )}
            </Grid>
            <Grid container className={classes.collections} xs={12}>
              <Grid item xs={2}></Grid>
              {collection && (
                <Grid item xs={10}>
                  <Card className={classes.cardRoot}>
                    <CardMedia
                      className={classes.dish}
                      component="img"
                      image={sampleFood}
                      title="sample food"
                    />
                    <div className={classes.details}>
                      <CardContent className={classes.content}>
                        <Typography>Belgian Waffle</Typography>
                        <Typography>May 28, 2019</Typography>
                        <Typography>857 Marine Drive West,</Typography>
                        <Typography>Vancouver, V7V 1H7, BC</Typography>
                      </CardContent>
                    </div>
                  </Card>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid container justify="flex-end" item xs={6}>
          <img src={Map} className={classes.map} />{" "}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Collection;
